<script setup lang="ts">
import { Navigation } from 'swiper/modules'

const { t } = useI18n()
const emit = defineEmits(['added-item'])
const { load: loadProducts, loading, products } = useGiftWrapProducts()
const isGiftWrappingOpen = ref(false)

const translatedCategoryName = {
  Kaarten: t('Cards'),
  'Gift bags': t('Gift bags'),
  'Gift boxes': t('Gift boxes'),
  'Sac cadeau': t('Gift bags'),
  'Pochette cadeau': t('Gift boxes'),
}

const toggleGiftWrapping = () => {
  if (!products.value.length && !loading.value) {
    loadProducts()
  }
  isGiftWrappingOpen.value = !isGiftWrappingOpen.value
}
</script>

<template>
  <div class="gift-wrapping">
    <div class="sf-accordion has-chevron">
      <SfButton
        class="sf-button--pure sf-accordion-item__header"
        :class="{ 'is-open': isGiftWrappingOpen }"
        :aria-pressed="isGiftWrappingOpen.toString()"
        :aria-expanded="isGiftWrappingOpen.toString()"
        data-testid="accordion-item-giftwrapping"
        @click="toggleGiftWrapping"
      >
        {{ $t('Gift? Add a gift wrap!') }}
        <SfChevron
          class="sf-accordion-item__chevron"
          :class="{ 'sf-chevron--right': !isGiftWrappingOpen }"
          tabindex="0"
        />
      </SfButton>
      <div v-if="isGiftWrappingOpen">
        <div class="sf-accordion-item__content">
          <div class="gift-wrapping__options">
            <SfLoader :loading="loading">
              <ul v-if="products" class="gift-wrapping__categories">
                <li v-for="(category, index) in products" :key="index">
                  <h3 class="gift-wrapping__category-title h4">
                    {{ translatedCategoryName[category.categoryName] || category.categoryName }}
                  </h3>
                  <Swiper
                    ref="swiperWrapper"
                    class="swiper-container gift-wrapping__products"
                    :modules="[Navigation]"
                    :speed="400"
                    :threshold="2"
                    :slidesPerView="3"
                    :spaceBetween="8"
                    :navigation="true"
                  >
                    <SwiperSlide v-for="(product, index) in category.products" :key="`${product.productId}-${index}`">
                      <CartGiftWrappingCard :product="product" />
                    </SwiperSlide>
                  </Swiper>
                </li>
              </ul>
            </SfLoader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gift-wrapping {
  position: relative;

  .sf-accordion {
    margin-bottom: 0;
    border-top-left-radius: var(--spacer-2xs);
    border-top-right-radius: var(--spacer-2xs);
    overflow: hidden;

    .sf-accordion-item__header {
      height: auto;
      padding: var(--spacer-base) var(--spacer-sm) var(--spacer-sm);
      line-height: 20px;

      @include for-desktop {
        padding: var(--spacer-base) var(--spacer-18) 19px var(--spacer-base);
        line-height: 22px;
      }

      &.is-open {
        background: var(--gray-background-color);
      }
    }

    .sf-accordion-item__content {
      padding: var(--spacer-xs);
    }
  }

  &__options {
    position: relative;
    min-height: var(--spacer-2xl);

    .sf-loader {
      --loader-overlay-background: var(--gray-background-color);
      min-height: var(--spacer-2xl);
      height: auto;
    }

    .gift-wrapping__products {
      :deep(.swiper-slide) {
        @include for-mobile {
          flex-shrink: 1;
        }
      }

      @include for-mobile {
        :deep(.swiper-slide-transform) {
          width: 120px;
          padding: 0;
        }
      }
    }
  }

  .gift-wrapping__category-title {
    font-family: var(--font-family--primary);
    font-size: var(--font-size--sm);
    font-weight: var(--font-weight--semibold);
    line-height: 1.4286;
    margin: 0 var(--spacer-xs) var(--spacer-xs);

    @include for-desktop {
      font-size: var(--font-size--base);
      line-height: var(--global-line-height);
      margin: 0 var(--spacer-sm) var(--spacer-xs);
    }
  }

  &__categories,
  &__products {
    position: relative;
    list-style: none;
    margin: 0 0 var(--spacer-sm);
    padding: 0;
  }

  &__products {
    position: relative;
  }

  :deep(.swiper-button-next),
  :deep(.swiper-button-prev) {
    top: 35%;
    background: #fff;
    width: 32px;
    height: 30px;
    padding: 8px 0;
    background-position: center;
  }

  :deep(.swiper-button-next) {
    right: 0;
  }

  :deep(.swiper-button-prev) {
    left: 0;
  }

  :deep(.swiper-button-disabled) {
    pointer-events: auto;
  }
}
</style>
