<script setup lang="ts">
const { loading, sizeChartItems, currentSizeChartRef, currentSizeChart: sizeChart } = storeToRefs(useSizeChartStore())
const { loadSizeChartItems, loadSizeChart } = useSizeChartStore()
const { products, activeProductSku } = storeToRefs(useProductStore())
const { routeData } = storeToRefs(usePageStore())
const categories = computed(() =>
  products.value[activeProductSku.value || routeData?.sku.value]?.categories?.map((c) => c.id),
)

const { toggleIsSizeChartSidebarOpen } = useUiState()
const { isSizeChartSidebarOpen } = storeToRefs(useUiState())
const { showWishlistModal } = storeToRefs(useWishlistStore())

watch(isSizeChartSidebarOpen, async (isOpen) => {
  // If the size chart sidebar is opened from any other page that the pdp,
  // Make sure to handle the state of the wishlist modal
  if (!isOpen && routeData.value.pageType !== 'PRODUCT') {
    showWishlistModal.value = true
    return
  }

  if (showWishlistModal.value) {
    showWishlistModal.value = false
  }

  await loadSizeChartItems()
  currentSizeChartRef.value = ''

  for (const categoryId of [...categories.value].reverse()) {
    const match = sizeChartItems.value.find((item) => parseInt(item.categoryId) === categoryId)
    if (match) {
      loadSizeChart(match.documentRef)
      break
    }
  }
})
</script>

<template>
  <div id="size-chart">
    <Sidebar position="right" :visible="isSizeChartSidebarOpen" @close="toggleIsSizeChartSidebarOpen">
      <HTMLContent v-if="sizeChart" class="product__size-chart rich-text" :content="sizeChart" />
      <p v-else-if="!loading && !sizeChart">{{ $t('No size chart available') }}</p>
    </Sidebar>
  </div>
</template>

<style lang="scss" scoped>
#size-chart {
  --sidebar-z-index: 22;
  --overlay-z-index: 22;

  :deep(table) {
    width: 100% !important;
  }
}

.product__size-chart {
  figure {
    margin: 0 0 var(--spacer-sm);
  }
}
</style>
