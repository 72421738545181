<script setup lang="ts">
import { langRedirectCookieName } from '~/enums/cookieNameEnum'
import deFlagIcon from '~/assets/icons/flags/de.svg'
import enFlagIcon from '~/assets/icons/flags/en.svg'
import frFlagIcon from '~/assets/icons/flags/fr.svg'
import nlFlagIcon from '~/assets/icons/flags/nl.svg'

export type Language = {
  code: string
  store: string
  label: string
  url: string
  icon: string
}

const { isMobile } = storeToRefs(useUiState())
const { hrefLangs } = storeToRefs(usePageStore())
const showLanguageSwitcher = ref(false)
const langRedirectCookie = useCookie(langRedirectCookieName, { maxAge: 60 * 60 * 24 * 365 })
const { locale } = useI18n()

const getUrl = (locale: string) => {
  const hrefLang = hrefLangs.value?.find((hrefLang) => hrefLang.locale === locale || hrefLang.language === locale)?.url
  return hrefLang || formatUrl(`${route.fullPath.replace(currentLocale.value, locale)}`)
}

const languages = computed<Language[]>(() => [
  { code: 'nl', store: 'con_nl', label: 'Dutch', url: getUrl('nl-nl'), icon: nlFlagIcon },
  { code: 'de', store: 'con_de', label: 'German', url: getUrl('de'), icon: deFlagIcon },
  { code: 'fr', store: 'con_fr', label: 'French', url: getUrl('fr'), icon: frFlagIcon },
  { code: 'en', store: 'con_en', label: 'English', url: getUrl('en'), icon: enFlagIcon },
])

const switchLanguage = (language: Language) => {
  langRedirectCookie.value = language.store
  if (language.store !== 'con_en') {
    navigateTo(language.url, { external: true })
  }
  showLanguageSwitcher.value = false
}

onMounted(() => {
  showLanguageSwitcher.value = !langRedirectCookie.value
  const currentStore = `con_${locale.value.split('-')[0]}`
  const language = languages.value.find((lang) => lang.store === langRedirectCookie.value)
  if (langRedirectCookie.value && langRedirectCookie.value !== currentStore) {
    navigateTo(language?.url, { external: true })
  }
})
</script>

<template>
  <UDrawer v-if="isMobile" v-model:open="showLanguageSwitcher" :dismissible="false">
    <template #body>
      <LanguageSwitcherContent :languages @switchLanguage="switchLanguage" />
    </template>
  </UDrawer>
  <UModal
    v-else
    v-model:open="showLanguageSwitcher"
    :dismissible="false"
    :ui="{ content: 'max-w-[400px] overflow-auto' }"
  >
    <template #content>
      <LanguageSwitcherContent :languages @switchLanguage="switchLanguage" />
    </template>
  </UModal>
</template>
