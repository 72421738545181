<script setup lang="ts">
import TimesIcon from '~/storefront/assets/icons/times.svg'

const hideShineyCombination = ref(false)
const close = () => {
  hideShineyCombination.value = true
  sessionStorage.setItem('hideShineyCombination', true)
}

onMounted(() => (hideShineyCombination.value = sessionStorage.getItem('hideShineyCombination')))
</script>

<template>
  <div v-if="!hideShineyCombination" class="shiny-combination" data-testid="shiny-combination">
    <p>{{ $t(`That's one shiny combination!`) }} ✨</p>
    <TimesIcon @click="close" />
  </div>
</template>

<style lang="scss" scoped>
.shiny-combination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-sm);
  width: 100%;
  height: 48px;
  padding: 0 var(--spacer-sm);
  border-radius: 4px;
  background: var(--gray-background-color);

  p {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }

  svg {
    cursor: pointer;
  }
}
</style>
