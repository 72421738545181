export type GiftWrapProduct = {
  productId: string
  price: string
  special_price: string
  sku: string
  name: string
  product_url: string
  media_url: {
    url: string
  }
  is_salable: boolean
  qty: number
}

type GiftWrapProductsResponse = {
  data: {
    categoryName: 'Kaarten' | 'Gift bags' | 'Gift boxes' | 'Sac cadeau' | 'Pochette cadeau'
    position: number
    products: GiftWrapProduct[]
  }[]
  success: boolean
}

export const useGiftWrapProducts = () => {
  const { locale } = useI18n()
  const loading = ref<boolean>(false)
  const products = ref<GiftWrapProductsResponse['data']>([])

  const load = async () => {
    loading.value = true
    const { data } = await $fetch<GiftWrapProductsResponse>(`/api/giftWrapProducts?locale=${locale.value}`).catch(
      () => ({
        success: false,
        data: [],
      }),
    )

    products.value = data
    loading.value = false
  }

  return {
    load,
    loading,
    products,
  }
}

export default useGiftWrapProducts
