<script setup lang="ts">
const { soldOutForm } = storeToRefs(useUiState())
const { setSoldOutFormData } = useUiState()
</script>
<template>
  <SfModal
    class="sf-modal product__sold-out-form"
    :visible="soldOutForm.isModalVisible"
    @close="setSoldOutFormData({ isModalVisible: false, optionLabel: null })"
  >
    <ProductSoldOutForm @toggleSoldOutForm="setSoldOutFormData({ isModalVisible: false, optionLabel: null })" />
  </SfModal>
</template>
<style lang="scss" scoped>
.product {
  &__sold-out-form {
    position: relative;
    z-index: 4;
  }
}
</style>
