export default (bannerID: string, bannerName: string, bannerAction: string = 'show') => {
  const exponeaData = {
    action: bannerAction,
    banner_id: bannerID,
    banner_name: bannerName,
    banner_type: 'banner',
    variant_id: 0,
    variant_name: 'Variant A',
    interaction: false,
    location: window.location.href,
    path: window.location.pathname,
  }
  window.exponea.track('banner', exponeaData)
}
