<script setup lang="ts">
import debounce from '~/utils/debounce'
import stockStatusEnum from '~/enums/stockStatusEnum'
import cartGetters from '~/utils/getters/magentoCartGetters'
import { getGiftcardHolders, isGiftcardHolder } from '~/composables/useCart/utils/giftcardHoldersUtils'
import type { CartItemInterface } from '@vue-storefront/magento-types'

const { transformImageUrlToSize, ImageSize } = useMagentoImage()

const props = defineProps({
  product: {
    type: Object as PropType<CartItemInterface>,
    required: true,
  },
})

const localePath = useLocalePath()
const cartStore = useCartStore()
const { cart } = storeToRefs(cartStore)
const { removeItem, updateItemQty, loading } = useCart()

const isDeleting = ref(false)
const isUpdatingQuantity = ref(false)

const getBundles = () => props.product.bundle_options?.map((b) => b.values).flat() || []

const actionRemoveItem = async () => {
  isDeleting.value = true
  await removeItem({ product: props.product })
  isDeleting.value = false
}

const delayedUpdateItemQty = debounce((params) => {
  if (params.quantity === 0) removeItem(params)
  else updateItemQty(params)
}, 500)

const invokeUpdateItemQty = (params) => {
  isUpdatingQuantity.value = true
  const { quantity, product } = params
  const cartItem = cart.value?.items?.find((item) => item!.uid === product?.uid)

  // SF-UI issue, doesn't set "zero" quantity -> Check for removal:
  if (quantity === 1 && cartItem?.quantity === quantity) {
    params.quantity = 0
  }

  delayedUpdateItemQty(params)
}

const isInStock = () => {
  if (props.product.__typename === 'ConfigurableCartItem') {
    return props.product?.configured_variant?.stock_status === stockStatusEnum.inStock
  }
  return props.product?.product?.stock_status === stockStatusEnum.inStock
}

const isDisabled = () => props.product.product.sku === 'mjgiftcard' || isGiftcardHolder(props.product.product)

const isDeletingDisabled = computed(
  () =>
    getGiftcardHolders(cart.value.items).length === 1 &&
    isGiftcardHolder(props.product.product) &&
    cart.value.items!.some((item) => item!.product.sku === 'mjgiftcard'),
)

watch(loading, (newVal) => {
  if (newVal === false) {
    isUpdatingQuantity.value = false
  }
})
</script>

<template>
  <SfCollectedProduct
    :key="`cartItem-${product.uid}`"
    class="collected-product"
    :image="product ? transformImageUrlToSize(cartGetters.getItemImage(product), ImageSize.Large) : ''"
    :title="cartGetters.getItemName(product)"
    :regular-price="$formatCurrency(product.regularPrice)"
    :special-price="product.specialPrice ? $formatCurrency(product.specialPrice) : null"
    :link="isGiftcardHolder(product.product) ? undefined : localePath(`/${product.product.url_key}.html`)"
    @input="invokeUpdateItemQty({ product, quantity: $event })"
  >
    <template #remove>{{ null }}</template>
    <template #input>
      {{ null }}
    </template>
    <template #configuration>
      <!-- bundles -->
      <div v-if="getBundles() && getBundles().length > 0">
        <SfProperty v-for="(bundle, i) in getBundles()" :key="i" :name="`${bundle.quantity}x`" :value="bundle.label" />
      </div>

      <ProductSpecialNote v-if="product" :product="product.product" :hide-size-special-note="true" />

      <!-- configurable_options:static -->
      <ul
        v-if="product.configurable_options || product.customizable_options"
        class="collected-product__configurable-options"
      >
        <li v-for="attr in product.configurable_options" :key="attr.configurable_product_option_uid">
          {{ attr && attr.value_label }}
        </li>
        <li v-for="attr in product.customizable_options" :key="attr.customizable_option_uid">
          {{ $t('Engraving:') }}
          <strong>{{ attr.values && attr.values[0] && attr.values[0].value }}</strong>
        </li>
      </ul>

      <CartQuantitySelector
        :qty="cartGetters.getItemQty(product)"
        :is-in-stock="isInStock()"
        :is-deleting="isDeleting"
        :is-deleting-disabled="isDeletingDisabled"
        :is-updating-quantity="isUpdatingQuantity"
        :aria-label="$t('Quantity')"
        :disabled="isDisabled()"
        @input="invokeUpdateItemQty({ product, quantity: $event })"
        @delete="actionRemoveItem()"
      />

      <p v-if="isInStock()" class="collected-product__in-stock">{{ $t('In stock') }}</p>
      <p v-else class="collected-product__out-of-stock">{{ $t('Out of stock') }}</p>
    </template>
    <template #actions>{{ null }}</template>
    <template #more-actions>{{ null }}</template>
  </SfCollectedProduct>
</template>

<style lang="scss" scoped>
.collected-product {
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  --collected-product-width: 100%;

  &:first-child {
    border-top-width: 0;
  }

  &__configurable-options {
    list-style: none;
    margin: 0 auto 0 0;
    padding: 0;
    font-size: var(--font-size-10);
    line-height: var(--spacer-18);
    color: var(--gray-primary-color);
    flex: 1;

    @include for-desktop {
      font-size: var(--font-size-13);
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &__in-stock,
  &__out-of-stock {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-size: var(--font-size-13);

    @include for-mobile {
      font-size: var(--font-size-10);
    }

    &:before {
      content: '';
      display: inline-block;
      margin-right: var(--spacer-xs);
      width: var(--spacer-xs);
      height: var(--spacer-xs);
      border-radius: 4px;
      background: #0a7807;
    }
  }

  &__out-of-stock {
    &:before {
      background: #d0021b;
    }
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
  }
  .sf-badge__absolute {
    position: absolute;
  }
}
</style>
